import { DURATION } from "@hey-lady/shared/helpers/const";
import {
  translateEnglishSkill,
  translateEnum,
  translateInterest,
} from "@hey-lady/shared/helpers/utils";
import { isAdminOrTeam } from "@hey-lady/shared/hooks/event";
import { useFormatMessages } from "@hey-lady/shared/hooks/general";
import { useAuth } from "@hey-lady/shared/hooks/user";
import {
  EnglishSkill,
  EventAvailabilityFilter,
  EventEnglishLevelFilter,
  EventHostedByFilter,
  EventTypeFilter,
  Interest,
  OccupationModel,
  UserLocation,
  useGetGroupedOccupationsQuery,
  useGetOccupationsQuery,
} from "@hey-lady/shared/types/graphql";
import LogoBranding from "@hey-lady/ui/atoms/LogoBranding";
import { format, parse } from "date-fns";
import { isNotEmpty, isNotNil, values } from "ramda";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import {
  AVAILABILITY_FILTER,
  AppliedFilterType,
  DEFAULT_EVENTS_FILTERS,
  ENGLISH_SKILLS_FILTER,
  EVENT_DATE_FILTER,
  EVENT_DURATION_FILTER,
  EVENT_FULL_EVENTS_FILTER,
  EVENT_TOPIC_FILTER,
  EventFilters,
  Filters,
  INTERESTS_FILTER,
  LOCATION_FILTER,
  MemberType,
  OCCUPATION_FILTER,
  PeopleEnglishLevelOptions,
  UrlFilterParams,
} from "./consts";
import { translateEventDurationFilter } from "./helpers";

export const useAppliedMembersFilters = (filters: Filters["people"]) => {
  const intl = useIntl();

  const [members, peopleIFollow, littleSisters, bigSisters, intermediate, advanced, proficient] =
    useFormatMessages([
      { id: "COMMON_FILTERS_MEMBERS" },
      { id: "PEOPLE_PEOPLE_I_FOLLOW" },
      { id: "PEOPLE_LITTLE_SISTERS" },
      { id: "PEOPLE_BIG_SISTERS" },
      { id: "PEOPLE_SEARCH_INTERMEDIATE" },
      { id: "PEOPLE_SEARCH_ADVANCED" },
      { id: "PEOPLE_SEARCH_PROFICIENT" },
    ]);

  const filterLabels = new Map<MemberType | PeopleEnglishLevelOptions, string>([
    [MemberType.LittleSisters, littleSisters as string],
    [MemberType.Members, members as string],
    [MemberType.BigSisters, bigSisters as string],
    [MemberType.Follow, peopleIFollow as string],
    [PeopleEnglishLevelOptions.Intermediate, intermediate as string],
    [PeopleEnglishLevelOptions.Advanced, advanced as string],
    [PeopleEnglishLevelOptions.Proficient, proficient as string],
  ]);

  const newFilters: AppliedFilterType[] = [];

  for (const [key, value] of Object.entries(filters)) {
    if (key === AVAILABILITY_FILTER) {
      continue;
    }
    if (Array.isArray(value)) {
      value.forEach((v) => {
        if (key === LOCATION_FILTER) {
          const location = v as UserLocation;
          if (location.city) {
            newFilters.push({ category: key, value: location.city, label: location.city });
          } else if (location.country) {
            newFilters.push({
              category: key,
              value: location.country,
              label: location.country,
            });
          }
        } else if (key === INTERESTS_FILTER) {
          const interest = v;
          newFilters.push({
            category: key,
            value: v as string,
            label: translateInterest(interest as Interest, intl).title,
          });
        } else if (key === ENGLISH_SKILLS_FILTER) {
          newFilters.push({
            category: key,
            value: v as string,
            label: translateEnglishSkill(v as EnglishSkill, intl).title,
          });
        } else if (key === OCCUPATION_FILTER) {
          const occupation = v as OccupationModel;
          newFilters.push({
            category: key,
            value: occupation.id,
            label: occupation.name || "",
          });
        } else {
          const labels = filterLabels.get(v as MemberType | PeopleEnglishLevelOptions);
          if (labels) {
            newFilters.push({
              category: key,
              value: v as string,
              label: labels,
            });
          }
        }
      });
    }
  }
  return newFilters;
};

type EventsFiltersOptions =
  | EventAvailabilityFilter
  | EventHostedByFilter
  | EventTypeFilter
  | EventEnglishLevelFilter;

export const useAppliedEventsFilters = (filters: EventFilters["events"]) => {
  const intl = useIntl();
  const [fullEvents, removeTopic] = useFormatMessages([
    { id: "EVENTS_PAGE_FULL_EVENTS" },
    { id: "EVENTS_PAGE_REMOVE_TOPIC" },
  ]);

  const availabilityOptions = useEventAvailabilityOptions();
  const hostedByOptions = useEventHostedByOptions();
  const eventTypeOptions = useEventTypeOptions();
  const englishLevelOptions = useEventEnglishLevelOptions();

  const eventFiltersOptions = [
    ...availabilityOptions,
    ...hostedByOptions,
    ...eventTypeOptions,
    ...englishLevelOptions,
  ];
  const mappedEventFiltersOptions = new Map(
    eventFiltersOptions.map((option) => [option.id, option.name]),
  );

  const occupationsOptions = useGetGroupedOccupationsOptions();

  const newFilters: AppliedFilterType[] = [];
  const occupations = (filters.occupations ?? [])
    .map((id) => occupationsOptions.find((occupation) => occupation.id === id))
    .filter(isNotNil) as OccupationModel[];

  for (const [key, value] of Object.entries(filters)) {
    if (Array.isArray(value)) {
      value.forEach((val) => {
        if (key === INTERESTS_FILTER) {
          newFilters.push({
            category: key,
            value: val as string,
            label: translateInterest(val as Interest, intl).title,
          });
        } else if (key === ENGLISH_SKILLS_FILTER) {
          newFilters.push({
            category: key,
            value: val as string,
            label: translateEnglishSkill(val as EnglishSkill, intl).title,
          });
        } else if (key === OCCUPATION_FILTER) {
          const occupation = occupations.find((o) => o.id === val);
          if (occupation) {
            newFilters.push({
              category: key,
              value: occupation.id,
              label: occupation.name || "",
            });
          }
        } else if (key === EVENT_DURATION_FILTER) {
          newFilters.push({
            category: key,
            value: val.toString(),
            label: translateEventDurationFilter(val as number, intl).title,
          });
        }
      });
    } else {
      if (key === EVENT_DATE_FILTER && value) {
        newFilters.push({
          category: key,
          value: value as string,
          label: format(parse(value as string, "yyyy-MM-dd", new Date()), "EEEE, LLL do, yyyy"),
        });
      } else if (key === EVENT_FULL_EVENTS_FILTER && value) {
        newFilters.push({
          category: key,
          value: value as string,
          label: fullEvents as string,
        });
      } else if (key === EVENT_TOPIC_FILTER && value) {
        newFilters.push({
          category: key,
          value: value as string,
          label: removeTopic as string,
        });
      } else {
        if (value !== DEFAULT_EVENTS_FILTERS.events[key as keyof EventFilters["events"]]) {
          const label = mappedEventFiltersOptions.get(value as EventsFiltersOptions);
          if (label) {
            newFilters.push({
              category: key,
              value: value as string,
              label,
            });
          }
        }
      }
    }
  }
  return newFilters;
};

export const useMembersOptions = (canSelectLittleSisters?: boolean) => {
  const membersOptions = [
    { value: MemberType.Members, name: useIntl().formatMessage({ id: "COMMON_FILTERS_MEMBERS" }) },
    { value: MemberType.BigSisters, name: useIntl().formatMessage({ id: "PEOPLE_BIG_SISTERS" }) },
    { value: MemberType.Follow, name: useIntl().formatMessage({ id: "PEOPLE_PEOPLE_I_FOLLOW" }) },
  ];
  const membersOptionsForHLT = [
    {
      value: MemberType.LittleSisters,
      name: useIntl().formatMessage({ id: "PEOPLE_LITTLE_SISTERS" }),
    },
    { value: MemberType.Members, name: useIntl().formatMessage({ id: "COMMON_FILTERS_MEMBERS" }) },
    { value: MemberType.BigSisters, name: useIntl().formatMessage({ id: "PEOPLE_BIG_SISTERS" }) },
    { value: MemberType.Follow, name: useIntl().formatMessage({ id: "PEOPLE_PEOPLE_I_FOLLOW" }) },
  ];
  return canSelectLittleSisters ? membersOptionsForHLT : membersOptions;
};

export const useEnglishLevelOptions = () => {
  return [
    {
      value: PeopleEnglishLevelOptions.Intermediate,
      name: useIntl().formatMessage({ id: "PEOPLE_SEARCH_INTERMEDIATE" }),
    },
    {
      value: PeopleEnglishLevelOptions.Advanced,
      name: useIntl().formatMessage({ id: "PEOPLE_SEARCH_ADVANCED" }),
    },
    {
      value: PeopleEnglishLevelOptions.Proficient,
      name: useIntl().formatMessage({ id: "PEOPLE_SEARCH_PROFICIENT" }),
    },
  ];
};

export const usePersonalInterestsOptions = () => {
  const intl = useIntl();
  const personalInterestsOptions = useMemo(() => {
    return values(Interest).map((interest) => translateInterest(interest, intl));
  }, [intl]);

  return personalInterestsOptions;
};

export const useEnglishInterestsOptions = () => {
  const intl = useIntl();
  const englishInterestsOptions = useMemo(() => {
    return values(EnglishSkill).map((englishSkill) => translateEnglishSkill(englishSkill, intl));
  }, [intl]);
  return englishInterestsOptions;
};

export const useGetOccupationOptions = () => {
  const intl = useIntl();
  const { data } = useGetOccupationsQuery({
    fetchPolicy: "cache-and-network",
  });

  const occupationOptions = data?.getOccupations
    ? data?.getOccupations.map((occupation) => ({
        id: occupation?.id,
        name: translateEnum(intl, occupation?.name),
      }))
    : [];

  return occupationOptions;
};

export const useGetGroupedOccupationsOptions = () => {
  const intl = useIntl();
  const { data } = useGetGroupedOccupationsQuery({
    fetchPolicy: "cache-and-network",
  });

  const groupedOccupationsOptions = data?.getGroupedOccupations
    ? data?.getGroupedOccupations.map((occupation: OccupationModel) => ({
        id: occupation?.id,
        name: translateEnum(intl, occupation?.name),
        groupName: translateEnum(intl, occupation?.industry?.name),
        meta: occupation || {},
      }))
    : [];

  return groupedOccupationsOptions;
};

export const useEventAvailabilityOptions = () => {
  return [
    {
      id: EventAvailabilityFilter.AnyTime,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_ANY_TIME" }),
    },
    {
      id: EventAvailabilityFilter.AvailableWhenIAm,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_AVAILABLE_WHEN_I_AM" }),
    },
  ];
};
export const useEventAvailabilityOptionsMap = () => {
  const options = useEventAvailabilityOptions();
  return new Map(options.map((option) => [option.id, option]));
};

export const useEventTypeOptions = () => {
  const { me } = useAuth();
  const isHeyLady = isAdminOrTeam(me);

  const newMemberMeetup = {
    id: EventTypeFilter.NewMemberMeetup,
    name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_NEW_MEMBER_WELCOME" }),
  };
  const membersEventTypeOptions = [
    {
      id: EventTypeFilter.AllEventTypes,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_ALL_EVENT_TYPES" }),
    },
    {
      id: EventTypeFilter.Conversation,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_CONVERSATIONS" }),
    },
    {
      id: EventTypeFilter.StudyAndLearn,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_STUDY_EVENTS" }),
    },
    {
      id: EventTypeFilter.DeepDive,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_DEEP_DIVE" }),
    },
    {
      id: EventTypeFilter.Professional,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_PROFESSIONAL_EVENTS" }),
    },
    {
      id: EventTypeFilter.ListenOnly,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_LISTEN_ONLY" }),
    },
    {
      id: EventTypeFilter.InPerson,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_IN_PERSON_EVENTS" }),
    },
  ];
  return isHeyLady ? [...membersEventTypeOptions, newMemberMeetup] : membersEventTypeOptions;
};

export const useEventEnglishLevelOptions = () => {
  return [
    {
      id: EventEnglishLevelFilter.All,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_EVENT_LEVEL" }),
    },
    {
      id: EventEnglishLevelFilter.AnyLevel,
      name: useIntl().formatMessage({ id: "COMMON_ANY_LEVEL" }),
    },
    {
      id: EventEnglishLevelFilter.Intermediate,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_INTERMEDIATE" }),
    },
    {
      id: EventEnglishLevelFilter.Advanced,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_ADVANCED" }),
    },
  ];
};

export const useEventHostedByOptions = () => {
  return [
    {
      id: EventHostedByFilter.Anyone,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_HOSTED_BY_ANYONE" }),
    },
    {
      id: EventHostedByFilter.HlTeam,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_TEAM" }, { HL: <LogoBranding /> }),
    },
    {
      id: EventHostedByFilter.PeopleIFollow,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_PEOPLE_I_FOLLOW" }),
    },
    {
      id: EventHostedByFilter.HlMembers,
      name: useIntl().formatMessage(
        { id: "EVENTS_PAGE_FILTERS_HL_MEMBERS" },
        { HL: <LogoBranding /> },
      ),
    },
    {
      id: EventHostedByFilter.HlCoach,
      name: useIntl().formatMessage(
        { id: "EVENTS_PAGE_FILTERS_HL_COACHES" },
        { HL: <LogoBranding /> },
      ),
    },
    {
      id: EventHostedByFilter.BigSisters,
      name: useIntl().formatMessage({ id: "EVENTS_PAGE_FILTERS_BIG_SISTERS" }),
    },
  ];
};

export const useEventDurationOptions = () => {
  const intl = useIntl();

  return DURATION.map((duration) => ({
    id: duration.value,
    label: translateEventDurationFilter(duration.value, intl).title,
  }));
};

export const useEventDurationOptionsMap = () => {
  const options = useEventDurationOptions();
  return new Map(options.map((option) => [option.id, option.label]));
};

type EventFiltersToGet =
  | typeof EventTypeFilter
  | typeof EventHostedByFilter
  | typeof EventAvailabilityFilter
  | typeof EventEnglishLevelFilter;
const getFilter = <T extends EventFiltersToGet>(
  filter: T,
  value: string | null,
  defaultValue: T[keyof T],
): T[keyof T] => {
  if (value && Object.values(filter).includes(value)) {
    return value as T[keyof T];
  }
  return defaultValue;
};

export const useGetEventFiltersFromUrl = () => {
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const type = getFilter(
    EventTypeFilter,
    query.get(UrlFilterParams.Type),
    EventTypeFilter.AllEventTypes,
  );
  const host = getFilter(
    EventHostedByFilter,
    query.get(UrlFilterParams.Host),
    EventHostedByFilter.Anyone,
  );
  const availability = getFilter(
    EventAvailabilityFilter,
    query.get(UrlFilterParams.Availability),
    EventAvailabilityFilter.AnyTime,
  );
  const englishLevel = getFilter(
    EventEnglishLevelFilter,
    query.get(UrlFilterParams.EnglishLevel),
    EventEnglishLevelFilter.All,
  );
  const showFullEvents = query.get(UrlFilterParams.Full) === "true";

  const eventDateString = query.get(UrlFilterParams.EventDate);
  const eventDate = eventDateString ?? undefined;

  const topicId = query.get(UrlFilterParams.TopicId) ?? undefined;
  const interests =
    (query?.get(UrlFilterParams.Interests)?.split(",").filter(isNotEmpty) as Interest[]) || [];
  const englishSkills =
    (query?.get(UrlFilterParams.EnglishSkills)?.split(",").filter(isNotEmpty) as EnglishSkill[]) ||
    [];
  const occupations = query?.get(UrlFilterParams.Occupations)?.split(",").filter(isNotEmpty) || [];
  const duration =
    query?.get(UrlFilterParams.Duration)?.split(",").filter(isNotEmpty).map(Number) || [];

  return {
    type,
    host,
    availability,
    englishLevel,
    showFullEvents,
    eventDate,
    topicId,
    interests,
    englishSkills,
    occupations,
    duration,
  };
};
