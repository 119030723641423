import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { differenceInMinutes } from "date-fns";
import { useForm } from "react-hook-form";
import type * as yup from "yup";

import {
  CREATE_USER_PHOTO,
  GENERATE_USER_UPLOAD_URL,
  UPDATE_ME,
} from "@hey-lady/gql/mutations/user";
import { ME, MY_PROFILE } from "@hey-lady/gql/queries/user";

import { type UpdateUserInput, type UserModel, useMeQuery } from "@hey-lady/shared/types/graphql";

import { noop } from "@hey-lady/shared/helpers/utils";
import { upload } from "../helpers/file";
import { useUploadFile } from "./uploadFile";
import { LOGIN_STORAGE_KEY } from "./useAuthUserTracking";

interface DefaultUpdateUserValues extends UpdateUserInput {
  location?: {
    city?: string | null | undefined;
    country?: string | null | undefined;
    cityId?: string | null | undefined;
    countryId?: string | null | undefined;
    lat?: number | null | undefined;
    long?: number | null | undefined;
  };
}

export const useAuth = () => {
  const { data, loading, error, refetch } = useMeQuery({
    onCompleted: (data) => {
      window.localStorage.setItem(LOGIN_STORAGE_KEY, JSON.stringify(data?.me.id));
    },
  });

  return {
    me: data?.me as UserModel,
    authenticated: !!data?.me,
    error,
    loading,
    refetch,
  } as const;
};

export const userProfileInput = (schema: DefaultUpdateUserValues) => {
  return {
    avatarUrl: schema?.avatarUrl,
    story: schema?.story,
    city: schema?.location?.city,
    country: schema?.location?.country,
    cityId: schema?.location?.cityId,
    countryId: schema?.location?.countryId,
    lat: schema?.location?.lat,
    long: schema?.location?.long,
    firstName: schema?.firstName,
    lastName: schema?.lastName,
    birthday: schema?.birthday,
    showBirthday: schema?.showBirthday,
    showBirthdayYear: schema?.showBirthdayYear,
    needSpeakingPartner: schema?.needSpeakingPartner,
    nativeLanguage: schema?.nativeLanguage,
    otherLanguage: schema?.otherLanguage,
    englishSkills: schema?.englishSkills,
    interests: schema?.interests,
    occupationId: schema?.occupationId,
    nationality: schema?.nationality,
  };
};

export const useSetupProfile = () => {
  const [updateMe, { loading, error }] = useMutation(UPDATE_ME, { refetchQueries: [ME] });

  const update = async (data: DefaultUpdateUserValues): Promise<void> => {
    const variables = {
      input: userProfileInput(data),
    };
    try {
      await updateMe({ variables });
    } catch (error) {
      console.error(error);
    }
  };

  return {
    update,
    loading,
    error,
  } as const;
};

export const useUpdateProfile = ({
  schema,
  defaultValues,
  onAfterUpdate = noop,
}: {
  schema: yup.ObjectSchema<any>;
  defaultValues: DefaultUpdateUserValues;
  onAfterUpdate: () => void;
}) => {
  const [updateMe, { loading, error }] = useMutation(UPDATE_ME, {
    refetchQueries: [{ query: ME }],
  });

  const { register, reset, control, getValues, formState, trigger, setValue } = useForm({
    mode: "all",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const update = async (): Promise<void> => {
    const variables = {
      input: userProfileInput(getValues()),
    };

    try {
      await updateMe({ variables });
      onAfterUpdate();
    } catch (_error) {}
  };

  return {
    loading,
    error,
    control,
    register,
    formState,
    trigger,
    update,
    reset,
    setValue,
  } as const;
};

export const useUpdateProfilePhoto = (onChange?: (avatarUrl: string) => void) => {
  const [updateMe] = useMutation(UPDATE_ME);
  const [generateUserUploadUrl] = useMutation(GENERATE_USER_UPLOAD_URL);

  const { loading, onDrop } = useUploadFile(async ([file]: File[]): Promise<void> => {
    const variables = {
      input: {
        filename: file.name,
      },
    };

    const { data } = await generateUserUploadUrl({ variables });
    await upload(data?.generateUserUploadUrl?.uploadURL, file);

    if (onChange) {
      onChange(data?.generateUserUploadUrl?.contentURL);
    }
    await updateMe({
      variables: { input: { avatarUrl: data?.generateUserUploadUrl?.contentURL } },
    });
  });

  return {
    loading,
    onDrop,
  } as const;
};

export const useUploadAttachmentPhoto = () => {
  const [generateUserUploadUrl] = useMutation(GENERATE_USER_UPLOAD_URL);
  const [createUserPhoto] = useMutation(CREATE_USER_PHOTO, {
    refetchQueries: [{ query: MY_PROFILE }],
  });
  const { loading, onDrop } = useUploadFile(async ([file]: File[]): Promise<void> => {
    const variables = {
      input: {
        filename: file.name,
      },
    };
    const { data } = await generateUserUploadUrl({ variables });
    await upload(data?.generateUserUploadUrl?.uploadURL, file);
    await createUserPhoto({
      variables: { input: { url: data?.generateUserUploadUrl?.contentURL } },
    });
  });

  return {
    loading,
    onDrop,
  } as const;
};

export const useIsUserOnline = (lastOnlineAt: Date | number | null) => {
  // TODO change to a more accurate approach (?)
  return lastOnlineAt ? differenceInMinutes(new Date(), lastOnlineAt) < 1 : false;
};
