import {
  ArticleByAuthor,
  ArticleCategory,
  AvailabilityStatus,
  EnglishSkill,
  EventAvailabilityFilter,
  EventEnglishLevelFilter,
  EventHostedByFilter,
  EventTypeFilter,
  Interest,
  OccupationModel,
  UserLocation,
} from "@hey-lady/shared/types/graphql";
import { ReactNode } from "react";

export interface Filters {
  articles: {
    category: ArticleCategory;
    author: ArticleByAuthor;
  };
  people: {
    availability: AvailabilityStatus[];
    membership: MemberType[];
    location: UserLocation[];
    interests: Interest[];
    englishSkills: EnglishSkill[];
    englishLevels: PeopleEnglishLevelOptions[];
    occupations: OccupationModel[];
  };
}
export interface EventFilters {
  events: {
    type: EventTypeFilter;
    host: EventHostedByFilter;
    availability: EventAvailabilityFilter;
    englishLevel: EventEnglishLevelFilter;
    showFullEvents: boolean;
    eventDate?: string;
    topicId?: string;
    interests?: Interest[];
    englishSkills?: EnglishSkill[];
    occupations?: string[]; // occupation ids
    duration?: number[];
  };
}

export interface AppliedFilterType {
  category: string;
  value: string;
  label: string | ReactNode[];
}

export interface SelectOption {
  id: string | undefined;
  name: string | ReactNode[] | undefined;
}

export enum UrlFilterParams {
  Availability = "availability",
  Type = "type",
  Host = "host",
  EnglishLevel = "level",
  EventDate = "date",
  Full = "full",
  TopicId = "topicId",
  Interests = "interests",
  EnglishSkills = "englishSkills",
  Occupations = "occupations",
  Duration = "duration",
}

export const AVAILABILITY_FILTER = "availability";
export const MEMBERS_FILTER = "membership";
export const LOCATION_FILTER = "location";
export const INTERESTS_FILTER = "interests";
export const ENGLISH_SKILLS_FILTER = "englishSkills";
export const OCCUPATION_FILTER = "occupations";
export const EVENT_TOPIC_FILTER = "topicId";
export const EVENT_DATE_FILTER = "eventDate";
export const EVENT_FULL_EVENTS_FILTER = "showFullEvents";
export const EVENT_DURATION_FILTER = "duration";

export const DEFAULT_PEOPLE_FILTERS = {
  people: {
    availability: [AvailabilityStatus.Shared],
    membership: [],
    location: [],
    interests: [],
    englishSkills: [],
    englishLevels: [],
    occupations: [],
  },
};

export const DEFAULT_EVENTS_FILTERS = {
  events: {
    type: EventTypeFilter.AllEventTypes,
    host: EventHostedByFilter.Anyone,
    availability: EventAvailabilityFilter.AnyTime,
    englishLevel: EventEnglishLevelFilter.All,
    showFullEvents: false,
    eventDate: undefined,
    topicId: undefined,
    interests: [],
    englishSkills: [],
    occupations: [],
    duration: [],
  },
};

export type PeopleFilterCategory = Exclude<keyof Filters["people"], "availability">;

export type EventsFilterCategory = keyof EventFilters["events"];

export enum MemberType {
  Follow = "Follow",
  LittleSisters = "Little Sisters",
  Members = "Members",
  BigSisters = "Big Sisters",
}

export interface EventsPageLinkState {
  pageName?: string;
  searchParams?: string;
}

export enum PeopleEnglishLevelOptions {
  Intermediate = "Intermediate",
  Advanced = "Advanced",
  Proficient = "Proficient",
}
